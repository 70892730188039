<template functional>
  <div
    class="modal-card modal-form"
    v-bind="data.attrs"
    v-on="listeners"
    :class="[data.staticClass, data.class]"
  >

    <!-- modal header -->
    <header
      class="modal-card-head"
      v-if="!!slots().header"
    >
      <slot name="header" />
    </header>

    <section
      class="modal-card-before-body"
      v-if="!!slots()['before-body']"
    >
      <slot name="before-body" />
    </section>

    <!-- body -->
    <section
      class="modal-card-body"
      v-if="!!slots().body"
    >
      <slot name="body" />
    </section>

    <!-- modal footer -->
    <footer
      class="modal-card-foot"
      v-if="!!slots().footer"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ModalCard',
}
</script>

<style lang="scss">
.modal-form {
  // modal head
  .modal-card-head {
    .modal-card-title {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
</style>
