import firebase from '@/module/firebase';
import { onBeforeUnmount, onMounted, Ref, watch, WatchSource } from '@vue/composition-api';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

// functions help working better with observable in Vue env

/** subscript observable, pass data to ref variable. */
export const subscribeWithRef = <T>(target: Ref<T>, source: Observable<T>) => {
  return source.pipe(tap((d) => (target.value = d)));
};

/** convert vue watch ability to rxjs observable. */
export const fromWatcher = <T>(watchTarget: WatchSource<T>, immediate = false, deep = false) => {
  return new Observable<T>((out) => {
    const watcher = watch(watchTarget, (v) => out.next(v), { immediate, deep });
    return () => {
      watcher();
    };
  }).pipe(
    // simulate behavious subject.
    shareReplay(1),
  );
};

//#region from vue ref
interface FromRefOpt {
  immediate?: boolean;
  deep?: boolean;
  atMount?: boolean;
}

/** create observable from Vue ref variable */
export const fromRef = <T>(refer: Ref<T>, opt: FromRefOpt = {}) => {
  const { immediate = false, deep = false, atMount = false } = opt;
  return new Observable<T>((out) => {
    let watcher: any;
    const initWatcher = () => {
      watcher = watch(refer, (v: any) => out.next(v), { immediate, deep });
    };

    // init at mount it require
    if (atMount) onMounted(initWatcher);
    else initWatcher();

    // clear when component unmount
    onBeforeUnmount(() => {
      watcher();
      out.complete();
    });

    // clear watcher if observable no more subscription
    return () => watcher();
  });
};
//#endregion

//#region subscribe with component
interface SubscribeWithComponentOpt {
  atMount?: boolean | number;
}

/** subscribe observable, bind with vue component lifecycle */
export const subscribeWithComponent = <T>(source: Observable<T>, opt: SubscribeWithComponentOpt = {}) => {
  const { atMount = true } = opt;
  let sub: Subscription | undefined = undefined;

  if (atMount === true) onMounted(() => (sub = source.subscribe()));
  else if (typeof atMount === 'number') {
    onMounted(() => {
      setTimeout(() => (sub = source.subscribe()), atMount);
    });
  } else sub = source.subscribe();
  onBeforeUnmount(() => sub?.unsubscribe());

  return sub;
};
//#endregion

//#region create observable from firebase ref
export const fromFirebaseDocRef = <T = firebase.firestore.DocumentData>(
  _ref: firebase.firestore.DocumentReference<T>,
) => {
  return new Observable<firebase.firestore.DocumentSnapshot<T>>((out) => {
    const unsub = _ref.onSnapshot({
      next: (i) => out.next(<any>i),
      error: (fail) => out.error(fail),
      complete: () => out.complete(),
    });
    return () => unsub();
  });
};
//#endregion

//#region create observable from firebase query ref
export const fromFirebaseQueryRef = <T = firebase.firestore.DocumentData>(_ref: firebase.firestore.Query<T>) => {
  return new Observable<firebase.firestore.QuerySnapshot<T>>((out) => {
    const unsub = _ref.onSnapshot({
      next: (i) => out.next(<any>i),
      error: (fail) => out.error(fail),
      complete: () => out.complete(),
    });
    return () => unsub();
  });
};
//#endregion
