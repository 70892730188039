import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/module/firebase';
import register from '../views/register/store';
import login from '../views/login/store';
import companyInfoStore from './general/company';
import { dangerBar } from '@/lib/notif';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    register,
    login,
    companyInfoStore,
  },
  state: {
    name: 'Prototype',
    author: 'Trung <office@trung.com>',
    // this is trick way to show loading when "guard" function route working
    preloading: true,
    // this is page load when switch between page protected
    user: null,
  },
  mutations: {
    user: (state, value) => (state.user = value),
  },
  actions: {
    getUserDetails: async ({ state, commit }, uid) => {
      try {
        var userData = await db
          .collection('Users')
          .doc(uid)
          .get()
          .then(d => ({ id: d.id, ...d.data() }));
        commit('user', userData);
      } catch (e) {
        console.log(e);
        dangerBar(e.message ? e.message : 'There was an error');
      }
    },
  },
  getters: {
    user: state => {
      return state.user;
    },
  },
});
