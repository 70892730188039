<template>
  <section class="navbar-worker is-top">
    <b-button
      :class="{
        'is-active-link': activeLink === 'worker-app-overview',
      }"
    >
      <menu-item-worker name-link="worker-app-overview" title-link="Timesheet" icon-name="stopwatch" />
    </b-button>
    <div class="user-nav-item">
      <img v-if="userData.photo" class="avatar" :src="userData.photo" alt="avatar" />
      <div
        v-else
        :style="{
          backgroundColor: userData.colorForDefaultPhoto,
        }"
        class="avatar"
      >
        {{ userData.name[0] }}
      </div>
    </div>
    <b-button @click="logout" icon-pack="fa" icon-right="sign-out-alt">Log out</b-button>
  </section>
</template>

<script>
import MenuItemWorker from '@/components/workers-component/menu-item-worker';
import { auth } from '@/module/firebase';
import { successToast } from '@/lib/notif';
import store from '@/store';
export default {
  name: 'WorkerNavBar',
  components: { MenuItemWorker },
  computed: {
    userData() {
      console.log(this.$store.getters.user);
      return this.$store.getters.user;
    },
    activeLink() {
      return this.$route.name;
    },
  },
  methods: {
    async logout() {
      try {
        await auth.signOut();
        successToast(`Good bye ${store.state.user.name}.`);
        store.commit('user', null);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-worker {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(246, 246, 246, 1);
  height: 40px;
  z-index: 20;

  .avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: relative;

    @media only screen and (min-width: 600px) {
      display: none;
    }
  }
  div.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .is-active-link {
    background-color: skyblue;
  }

  @media only screen and (min-width: 600px) {
    height: 60px;
  }
}

.is-top {
  @media only screen and (min-width: 600px) {
    top: 0;
  }
  @media only screen and (max-width: 600px) {
    bottom: 0;
  }
}
</style>