<template>
  <router-link class="worker-link-item" :to="{name: nameLink}">
    {{titleLink}}<b-icon style="margin-left: 10px;" :icon="iconName" pack="fa" type="is-primary"></b-icon>
  </router-link>
</template>

<script>
export default {
  props: {
    nameLink: {type: String, default: ''},
    titleLink: {type: String, default: ''},
    iconName: {type: String, default: ''},
  },
  name: 'MenuItemWorker'
}
</script>

<style lang="scss" scoped>
.worker-link-item {
  color: #2b343f;
}
</style>