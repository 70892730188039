/*
ROUTER GUARD
guard before each access in normal page
check authenticate
*/
import metaUpdate from './meta-update';
import { NavigationGuard } from 'vue-router';
import { useAppService } from '@/module/app/AppService';
import { firstValueFrom, Subscription } from 'rxjs';
import { useAuthService } from '@/module/auth/AuthService';
import { useUserService } from '@/module/user/UserService';
import { useCompanyService } from '@/module/company/CompanyService';

const main: NavigationGuard = async (to, from, next) => {
  const { pageloading } = useAppService();
  const { authorize$ } = useAuthService();
  const { user$ } = useUserService();
  const { company$ } = useCompanyService();

  // update meta tags
  metaUpdate(to.meta);

  pageloading.value = true;

  const auth = await firstValueFrom(authorize$);
  if (!auth) return next({ name: 'login' });

  // setup user service start observing user data
  await firstValueFrom(user$);
  await firstValueFrom(company$);
  // setup company service start observing company data

  pageloading.value = false;

  return next();
};

export default main;
