
import { useRouter } from '@/router';
import { computed, defineComponent } from '@vue/composition-api';
import SidebarMenuMain from './SidebarMenuMain.vue';
import SidebarMenuSetting from './SidebarMenuSetting.vue';
import SidebarUser from './SidebarUser.vue';

export default defineComponent({
  components: { SidebarUser, SidebarMenuMain, SidebarMenuSetting },
  props: {},
  setup() {
    const router = useRouter();

    //#region switch between menus
    const inSettingPages = computed(() => router.currentRoute.name?.startsWith(`setting`));
    const inMainPages = computed(() => !inSettingPages.value);
    //#endregion

    return { inSettingPages, inMainPages };
  },
});
