
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: {},
  props: {
    to: {
      type: [String, Object] as PropType<string | any>,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    exact: { type: Boolean, default: () => false },
  },
  setup() {
    return {};
  },
});
