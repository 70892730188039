import { render, staticRenderFns } from "./tapping-nav.vue?vue&type=template&id=3151cde2&functional=true&"
import script from "./tapping-nav.vue?vue&type=script&lang=js&"
export * from "./tapping-nav.vue?vue&type=script&lang=js&"
import style0 from "./tapping-nav.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports