import { createMutate } from '@/lib/store-helper'

export default {
    namespaced: true,
    state: {
        input: null,
        confirmationResult: null
    },
    mutations: {
        ...createMutate('input', 'confirmationResult')
    }
}