export const decor = {
  logoLight: () => import(/* webpackChunkName: 'decor' */ './logo-light'),
  logoDark: () => import(/* webpackChunkName: 'decor' */ './logo-dark'),
  logoIcon: () => import(/* webpackChunkName: 'decor' */ './logo-icon'),
  icon: () => import(/* webpackChunkName: 'decor' */ './icon'),
  dropdown: () => import(/* webpackChunkName: 'decor' */ './dropdown'),
  title: () => import(/* webpackChunkName: 'decor' */ './title'),
  card: () => import(/* webpackChunkName: 'decor' */ './card'),
  hozStack: () => import(/* webpackChunkName: 'decor' */ './hoz-stack'),
  iconSkeletonAnim: () =>
    import(/* webpackChunkName: 'decor' */ './icon-skeleton-anim'),
  space: () => import(/* webpackChunkName: 'decor' */ './space'),
  copyright: () => import(/* webpackChunkName: 'decor' */ './copyright'),
  hrBig: () => import(/* webpackChunkName: 'decor' */ './hr-big'),
  lineDotTag: () => import(/* webpackChunkName: 'decor' */ './line-dot-tag'),
  addonText: () => import(/* webpackChunkName: 'decor' */ './addon-text'),
  dotCircle: () => import(/* webpackChunkName: 'decor' */ './dot-circle'),
  titleSub: () => import(/* webpackChunkName: 'decor' */ './title-sub'),
  message: () => import(/* webpackChunkName: 'decor' */ './message'),
  circleLoading: () =>
    import(/* webpackChunkName: 'decor' */ './circle-loading'),
  circleFullLoading: () =>
    import(/* webpackChunkName: 'decor' */ './circle-full-loading'),

  breadcrumb: () => import(/* webpackChunkName: 'decor' */ './breadcrumb'),
  breadcrumbLine: () =>
    import(/* webpackChunkName: 'decor' */ './breadcrumb-line'),
  boxEmpty: () => import(/* webpackChunkName: 'decor' */'./box-empty'),
  boxWhiteCenter: () => import(/* webpackChunkName: 'decor' */'./box-white-center')
}

export const install = {
  install: Vue => {
    Vue.component('decor-logo-light', decor.logoLight)
    Vue.component('decor-logo-dark', decor.logoDark)
    Vue.component('decor-logo-icon', decor.logoIcon)
    Vue.component('decor-icon', decor.icon)
    Vue.component('decor-dropdown', decor.dropdown)
    Vue.component('decor-title', decor.title)
    Vue.component('decor-card', decor.card)
    Vue.component('decor-hoz-stack', decor.hozStack)
    Vue.component('decor-icon-skeleton-anim', decor.iconSkeletonAnim)
    Vue.component('decor-space', decor.space)
    Vue.component('decor-copyright', decor.copyright)
    Vue.component('decor-hr-big', decor.hrBig)
    Vue.component('decor-line-dot-tag', decor.lineDotTag)
    Vue.component('decor-addon-text', decor.addonText)
    Vue.component('decor-dot-circle', decor.dotCircle)
    Vue.component('decor-circle-loading', decor.circleLoading)
    Vue.component('decor-title-sub', decor.titleSub)
    Vue.component('decor-circle-full-loading', decor.circleFullLoading)
    Vue.component('decor-message', decor.message)
    Vue.component('decor-breadcrumb', decor.breadcrumb)
    Vue.component('decor-breadcrumb-line', decor.breadcrumbLine)
    Vue.component('decor-box-empty', decor.boxEmpty)
    Vue.component('decor-box-white-center', decor.boxWhiteCenter)
  },
}
