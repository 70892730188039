<template functional>
  <nav class="tabs tabbing-nav">
    <ul>
      <li
        v-for="t in props.tabs"
        :key="t"
        :class="{ 'is-active': t === props.value }"
      >
        <a @click="$options.select(listeners, t)">
          <span>{{ t }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TappingNav',
  props: {
    // list of tags (name only)
    tabs: { type: Array, default: () => [] },
    // current tab will be show
    value: { type: [String, Number], default: () => null },
  },
  // on selected
  select: (listeners, value) => listeners.input(value),
}
</script>

<style lang="scss">
.tabbing-nav {
  > ul {
    padding-top: 1em;
  }
}
</style>
