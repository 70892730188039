/**
 * modules connect to database and stick with jabpay project
 */

//  source import
export const sources = {
  date: () => import(/* webpackChunkName: 'select_source' */ './select-date'),
  
  time: () => import(/* webpackChunkName: 'select_time' */ './select-time'),
  timezones: () =>
    import(/* webpackChunkName: 'select_source' */ './select-timezones'),

  roles: () =>
    import(/* webpackChunkName: 'select_source' */ './select-roles'),

  businessTypes: () =>
    import(/* webpackChunkName: 'select_source' */ './select-business-types'),

  businessDescs: () =>
    import(/* webpackChunkName: 'select_source' */ './select-business-descs'),

  addresses: () =>
    import(/* webpackChunkName: 'select_source' */ './select-addresses'),

  farmers: () =>
    import(/* webpackChunkName: 'select_famer' */ './select-farmers'),
}

export default {
  install: Vue => {
    //
    Vue.component('source-select-addresses', sources.addresses)
    Vue.component('source-select-timezones', sources.timezones)
    Vue.component('source-select-date', sources.date)
    Vue.component('source-select-roles', sources.roles)
    Vue.component('source-select-business-types', sources.businessTypes)
    Vue.component('source-select-business-descs', sources.businessDescs)
    Vue.component('source-select-farmers', sources.farmers)
  },
}
