// core style
import './style/tailwind.css';
import './style/index.scss';
import 'dropzone/dist/dropzone.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

import './registerServiceWorker';

// INIT APP FRAMEWORK
console.info(`Construction App`);

import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

import Vuex from 'vuex';
// @ts-ignore
import VeeValidate from 'vee-validate/dist/vee-validate.esm';
import VueRx from 'vue-rx';
// @ts-ignore
import Croppa from 'vue-croppa';

Vue.use(Vuex);
Vue.use(VeeValidate, { inject: false });
Vue.use(VueRx);
Vue.use(Croppa);

import Buefy from 'buefy';

Vue.use(Buefy, {
  defaultIconPack: 'fal',
});

// import decors component
import { install as DecorInstall } from '@/components/decor';
Vue.use(DecorInstall);

import { install as ControlInstall } from '@/components/control';
Vue.use(ControlInstall);

import { install as LogicInstall } from '@/components/logic';
Vue.use(LogicInstall);

import Dataset from '@/components/dataset';
Vue.use(Dataset);

//Filters PIPES
import transformDateToCustomFormat from './pipes/transformCustomDate';
Vue.filter('transformDateToCustomFormat', transformDateToCustomFormat);

import Modal from '@/components/modal';
Vue.use(Modal);

// @ts-ignore
import CoolLightBox from 'vue-cool-lightbox';
Vue.use(CoolLightBox);

//tippy
// @ts-ignore
import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy);
// or
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
    },
  },
});

Vue.component('tippy', TippyComponent);
//tippy

import App from './App.vue';
// add internal modules
import router from './router';
// container store
import store from './store';

import Tabbing from '@/components/tabbing';
Vue.use(Tabbing);

import SourceModules from '@/components/select_source';
Vue.use(SourceModules);

import SearchGlobal from '@/components/search_global/index.vue';
Vue.component('search-global', SearchGlobal);

// @ts-ignore
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput, { inputOptions: { placeholder: '' } });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
