<template functional>
  <div>
    loading
  </div>
</template>

<script>
export default {
  name: 'Preload',
};
</script>
