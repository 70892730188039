// collect buefy we need to use many times
import { ModalProgrammatic, SnackbarProgrammatic, ToastProgrammatic } from 'buefy';

export const dangerBar = (message: string) => {
  SnackbarProgrammatic.open({
    duration: 4000,
    type: 'is-danger',
    position: 'is-bottom-right',
    queue: false,
    message: message,
  });
  console.warn(`Danger bar:`, message);
};

export const successBar = (message: string) =>
  SnackbarProgrammatic.open({
    duration: 3000,
    type: 'is-success',
    position: 'is-bottom-right',
    queue: false,
    message,
  });

export const dangerToast = (message: string) =>
  ToastProgrammatic.open({
    type: 'is-danger',
    duration: 5000,
    message,
    queue: false,
  });

export const successToast = (message: string) =>
  ToastProgrammatic.open({
    type: 'is-success',
    duration: 3000,
    message,
    queue: false,
  });

export const modal = (ctx: any, component: any, width: number, events: any, props: any) =>
  ModalProgrammatic.open({
    component,
    parent: ctx,
    canCancel: false,
    scroll: 'keep',
    hasModalCard: true,
    width,
    events,
    props,
  });
