import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import beforeEnterAuth from '@/guard/before-enter-auth';
import beforeEnterLogin from '@/guard/before-enter-login';
import loading from '@/guard/preload.vue';
import { RegisterRoutes } from '../views/register/routes';
import { LoginRoutes } from '../views/login/routes';
import { WorkerRoutes } from './worker-router';
import { inject, provide } from '@vue/composition-api';

Vue.use(VueRouter);

const makeComponent = (get: any) => () => ({
  component: get(),
  loading,
});

const meta = (obj: any) => Object.assign({ layout: 'main' }, obj);

const routes = <RouteConfig[]>[
  {
    name: 'places',
    path: '/',
    component: makeComponent(() => import(/* webpackChunkName: 'places' */ '../views/places/index.vue')),
    meta: meta({ title: 'Projects' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'project_detail',
    path: '/project-details/:project_id',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'project-detail' */ '../views/project-detail/index.vue'),
    ),
    meta: meta({ title: 'Project Detail' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'requests',
    path: '/requests',
    component: makeComponent(() => import(/* webpackChunkName: 'requests' */ '../views/requests/index.vue')),
    meta: meta({ title: 'Requests' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'request.modify',
    path: '/request/modify/:request_id',
    component: makeComponent(() => import(/* webpackChunkName: 'requests' */ '../views/request_form/index.vue')),
    meta: meta({ title: 'Modify Request' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'supplies',
    path: '/supplies',
    component: makeComponent(() => import(/* webpackChunkName: 'supplies' */ '../views/supplies/index.vue')),
    meta: meta({ title: 'Supplies' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'tools',
    path: '/tools',
    component: makeComponent(() => import(/* webpackChunkName: 'tools' */ '../views/tools/index.vue')),
    meta: meta({ title: 'Tools' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'workers',
    path: '/workers',
    component: makeComponent(() => import(/* webpackChunkName: 'workers' */ '../views/workers/index.vue')),
    meta: meta({ title: 'People' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'reports',
    path: '/reports',
    component: makeComponent(() => import(/* webpackChunkName: 'requests' */ '../views/reports/index.vue')),
    meta: meta({ title: 'Updates' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'report.details',
    path: '/report/details/:report_id',
    component: makeComponent(() => import(/* webpackChunkName: 'requests' */ '../views/report_form/index.vue')),
    meta: meta({ title: 'Report Details' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'time_schedule_workers',
    path: '/time-schedule-workers',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'time_schedule_workers' */ '../views/time_schedule_workers/index.vue'),
    ),
    meta: meta({ title: 'Timesheets' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'project-worker',
    path: '/project-worker',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'project.worker' */ '../views/project-detail/index.vue'),
    ),
    meta: meta({ title: 'Timesheets' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'reset.password',
    path: '/reset-password',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'reset.password' */ '../views/reset_password/index.vue'),
    ),
    meta: meta({ title: 'Reset Passwrod', layout: 'custom' }),
    beforeEnter: beforeEnterLogin,
  },
  {
    name: 'change.password',
    path: '/change-password',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'change.password' */ '../views/change_password/index.vue'),
    ),
    meta: meta({ title: 'Change Passwrod', layout: 'custom' }),
    beforeEnter: beforeEnterLogin,
  },
  {
    name: 'userSettings',
    path: '/user-settings',
    component: makeComponent(() => import(/* webpackChunkName: 'userSettings' */ '../views/user-settings/index.vue')),
    meta: meta({ title: 'My Profile' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'design',
    path: '/design',
    component: makeComponent(() => import(/* webpackChunkName: 'design' */ '../views/design-page/index.vue')),
    meta: meta({ title: 'Design' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'designCreate',
    path: '/design-create',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'designCreate' */ '../views/design-create-page/index.vue'),
    ),
    meta: meta({ title: 'Design Create' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'designDetail',
    path: '/design-detail/:design_id',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'designDetail' */ '../views/design-detail-page/index.vue'),
    ),
    meta: meta({ title: 'Design' }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'document',
    path: '/documents',
    component: makeComponent(() => import(`../views/document-page/DocumentPage.vue`)),
    meta: meta({ title: `Documents` }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'schedule',
    path: '/schedules',
    component: makeComponent(() => import(`../views/schedule/SchedulePage.vue`)),
    meta: meta({ title: `Schedule` }),
    beforeEnter: beforeEnterAuth,
  },

  //#region settings
  {
    name: 'setting.project-type',
    path: '/settings/project-type',
    component: makeComponent(() => import(`../views/setting/project-type/ProjectTypePage.vue`)),
    meta: meta({ title: `Project type manager` }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'setting.document-type',
    path: '/settings/document-type',
    component: makeComponent(() => import(`../views/setting/document-type/DocumentTypePage.vue`)),
    meta: meta({ title: `Document type manager` }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'setting.fixer',
    path: '/settings/fixer',
    component: makeComponent(() => import(`../views/setting/fixer/FixerPage.vue`)),
    meta: meta({ title: `Fixer` }),
    beforeEnter: beforeEnterAuth,
  },
  //#endregion

  // //#region punchlist
  // {
  //   name: 'tasklist',
  //   path: '/tasklists',
  //   component: makeComponent(() => import('../views/punchlist/PunchlistPage.vue')),
  //   meta: meta({ title: `Task lists` }),
  //   beforeEnter: beforeEnterAuth,
  // },
  // {
  //   name: 'tasklist.detail',
  //   path: '/tasklists/:punchlist_id',
  //   component: makeComponent(() => import('../views/punchlist/PunchlistDetailPage.vue')),
  //   meta: meta({ title: `Task list` }),
  //   beforeEnter: beforeEnterAuth,
  // },
  // //#endregion

  //#region tasklist
  {
    name: 'tasklist',
    path: '/tasklists',
    component: makeComponent(() => import('../views/tasklist/TasklistPage.vue')),
    meta: meta({ title: `Task lists` }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'tasklist.create',
    path: '/tasklists/create',
    component: makeComponent(() => import('../views/tasklist/TasklistDetailPageCreate.vue')),
    meta: meta({ title: `Create task list` }),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'tasklist.detail',
    path: '/tasklists/:tasklistId',
    component: makeComponent(() => import('../views/tasklist/TasklistDetailPage.vue')),
    meta: meta({ title: `Task list` }),
    beforeEnter: beforeEnterAuth,
  },
  //#endregion

  //#region procurement
  {
    name: 'procurement',
    path: '/procurement',
    meta: meta({ title: `Procurement` }),
    component: makeComponent(() => import('../views/procurement-dev/ProcurementPage.vue')),
    beforeEnter: beforeEnterAuth,
  },
  {
    name: 'procurement.detail',
    path: '/procurement/:procurement_id',
    component: makeComponent(() => import('../views/procurement-dev/ProcurementDetailPage.vue')),
    meta: meta({ title: `Procurement` }),
    beforeEnter: beforeEnterAuth,
  },
  //#endregion

  //#region leads
  {
    name: 'lead',
    path: '/leads',
    component: makeComponent(() => import('../views/leads/LeadPage.vue')),
    meta: meta({ title: `Lead` }),
    beforeEnter: beforeEnterAuth,
  },
  //#endregion

  {
    name: 'contact',
    path: '/contacts',
    component: makeComponent(() => import(`../views/contact/ContactPage.vue`)),
    meta: meta({ title: `Contact` }),
    beforeEnter: beforeEnterAuth,
  },
];

const router = new VueRouter({
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(RegisterRoutes, LoginRoutes, WorkerRoutes),
});

export default router;

const token = 'Router';
export const createRouterProvider = (routerInstance: any) => provide(token, routerInstance);
export const useRouter = () => inject(token) as typeof router;

const originalPush = router.push;
// @ts-ignore
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  // @ts-ignore
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};
