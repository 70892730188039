import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/module/firebase';
import { useAppService } from '@/module/app/AppService';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    companyInfo: {},
  },
  mutations: {
    companyInfo: (state: any, value: any) => (state.companyInfo = value),
  },
  actions: {
    fetchCompanyInfo: async ({ state, commit, rootState, rootGetters }: any) => {
      const { isAdminRoot } = useAppService();
      try {
        const userID = rootState.user.id;
        const companyId = rootState.user.companyId;
        isAdminRoot.value = (
          await db
            .collection('Companies')
            .doc(companyId)
            .get()
            .then((d) => {
              const obj = {
                admins: [],
                id: d.id,
                ...d.data(),
              };
              state.companyInfo = { ...obj };
              return { ...obj };
            })
        ).admins.some((i) => i === userID);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    companyInfo: (state: any) => {
      return state.companyInfo;
    },
  },
};
