
import { defineComponent, onBeforeUnmount, reactive, watch } from '@vue/composition-api';
import PageLayout from './components/decor/page-layout.vue';
import PageWorkerLayout from './components/decor/page-worker-layout.vue';
import { createRouterProvider, useRouter } from './router';
import { useAppService } from './module/app/AppService';
import { useAuthService } from './module/auth/AuthService';
import { useCompanyService } from '@/module/company/CompanyService';
import { useUserService } from './module/user/UserService';
import { useThemeService } from '@/module/theme/ThemeService';
import Drawer from './components/drawer/Drawer.vue';

export default defineComponent({
  components: {
    PageLayout,
    PageWorkerLayout,
    Drawer,
  },
  setup(props, { root }) {
    const { pageloading, preloading, isAdminRoot, layout } = useAppService();
    const { authorize$ } = useAuthService();
    const { observingUser$ } = useUserService();
    const { observingCompany$ } = useCompanyService();
    const { windowSize$ } = useThemeService();

    // observing window width.
    windowSize$.subscribe((i) => console.log(i));

    //#region create provide router
    createRouterProvider(reactive(root.$router));
    const router = useRouter();
    //#endregion

    //#region implement layout and perm key from current router
    watch(
      () => router.currentRoute,
      (crrouter) => (layout.value = crrouter.meta?.layout),
      { immediate: true },
    );
    //#endregion

    // dont know what idea here.
    watch(isAdminRoot, (yes) => yes ?? router.replace({ name: 'worker-app' }), { immediate: true });

    //#region authorize setup
    // setup observing thought firebase auth library. Router guard will take responsibility checking.
    const authorizeSub = authorize$.subscribe();
    onBeforeUnmount(() => authorizeSub.unsubscribe());
    //#endregion

    observingUser$.subscribe();
    observingCompany$.subscribe();

    //#region observing company
    //#endregion

    return { pageloading, preloading, layout, isAdminRoot };
  },
});
