import { date, random, name } from 'faker'
import { DateTime } from 'luxon'

pastDate = -> DateTime.fromJSDate(date.past()).toLocaleString DateTime.DATE_SHORT

f = (id) -> (
  type = random.arrayElement ['order', 'nest_run', 'producer']
  title = (
    switch type
      when 'order' then "Order ##{random.number()}"
      when 'nest_run' then "Nest Run ##{random.number()}"
      when 'producer' then "Producer ##{random.number()}"
  )
  desc = (
    switch type
      when 'order' then "Created at #{  }"
      when 'nest_run' then "Farmer #{name.findName()} at #{pastDate()}"
      when 'producer' then "at #{pastDate()}"
  )

  { id, title, desc, type }
)


export default [1..50].map (id) -> f id