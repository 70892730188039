import { ref, Ref } from '@vue/composition-api';

let init = false;
let pageloading: Ref<boolean>;
let preloading: Ref<boolean>;
let isAdminRoot: Ref<boolean>;
/** app layout name */
let layout: Ref<string>;
/** permission key */
let permkey: Ref<string>;

//#region common info
let name: Ref<string>;
let author: Ref<string>;
//#endregion

/** app service */
export const useAppService = () => {
  if (!init) {
    init = true;
    pageloading = ref(false);
    preloading = ref(false);
    isAdminRoot = ref(false);
    layout = ref('');
    permkey = ref('');
    name = ref('Admin construction');
    author = ref(`Trung <trung@intrabuilds.com>`);
  }

  return { pageloading, isAdminRoot, layout, permkey, name, author, preloading };
};
