































export default {
  name: "SearchGlobal"
  data: -> {
    data: require('./sample.coffee').default
    name: ''
    selected: null
  }
  computed: {
    filteredDataArray: ->
      i = this.name.toLowerCase()
      compare = (a) -> (new RegExp(i, 'g')).test a

      this.data.filter (option) ->
        title = option.title.toLowerCase()
        id = option.id.toString()
        desc = option.desc.toLowerCase()
        compare(title) or compare(id) or compare(desc)
  }
  methods: {
    isFocus: -> this.$emit 'open'
    isBlur: -> this.$emit 'close'
  }
}
