import { nextTick, ref, Ref } from '@vue/composition-api';

let init = false;
let open: Ref<boolean>;
let comp: Ref<unknown | undefined>;
let props: Ref<{ [k: string]: any } | undefined>;
let widthSize: Ref<'small' | 'medium' | 'large'>;

type UncloseIf = (target?: Element) => boolean;
type OnClose = () => void;

const functions = {
  uncloseIf: (() => false) as UncloseIf,
  onClose: (() => {}) as OnClose,
};

type WidthSizeId = 'small' | 'medium' | 'large';

interface Opt extends Partial<typeof functions> {
  widthSize?: WidthSizeId;
}

const setOpen = (component?: unknown, _props: { [k: string]: any } = {}, opt: Opt = {}) => {
  if (component) comp.value = component;
  if (_props) props.value = _props;
  open.value = true;
  if (opt.widthSize) widthSize.value = opt.widthSize;
  if (opt.uncloseIf) functions.uncloseIf = opt.uncloseIf;
  if (opt.onClose) functions.onClose = opt.onClose;
};

const setClose = () => {
  open.value = false;

  nextTick(() => {
    comp.value = undefined;
    props.value = undefined;
    if (functions.onClose) functions.onClose();
  });
};

export const useDrawer = () => {
  if (!init) {
    init = true;
    open = ref(false);
    comp = ref();
    props = ref();
    widthSize = ref('small');
  }
  return { open, comp, props, setOpen, setClose, widthSize, functions };
};
