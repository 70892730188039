import firebase, { db, FirebaseConverter, FirebaseEntryBase, FirebaseEntryEmbededSetting } from '@/module/firebase';
import { defer, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { fromFirebaseDocRef } from '@/lib/UtilObservable';
import { useAuthService } from '@/module/auth/AuthService';
import store from '@/store';
import { CustomSettingTimeSheetEntry } from '../custom-settings/CustomSettingsService';
import { UserEntryEmbededSettingValues } from './UserEmbededSettingService';

/** user entry */
export interface UserEntry extends FirebaseEntryBase, FirebaseEntryEmbededSetting<UserEntryEmbededSettingValues> {
  apnsToken: string;
  colorForDefaultPhoto: string;
  companyId: string;
  email: string;
  fcmToken: string;
  isAdmin: boolean;
  name: string;
  phone: string;
  photo: string;
  preferLanguage: 'english' | 'spanish' | 'greek';
  status: string;
  isShowInTimesheet: boolean;
  signUpMethod: string;
}

export interface UsserEmbededSettingEntryValues {}

export const useUserConverter: FirebaseConverter<UserEntry> = {
  fromFirestore: (s) => {
    const item = <UserEntry>{
      preferLanguage: 'english',
      isShowInTimesheet: false,
      colorForDefaultPhoto: `blue`,
      __path: s.ref.path,
      ...s.data(),
      id: s.id,
    };
    return item;
  },
  toFirestore: (entry) => {
    const { __path, id, ...data } = entry;
    return data;
  },
};

const userCollectionName = 'Users';

const getEntry = (doc: firebase.firestore.DocumentSnapshot) =>
  <UserEntry>{
    preferLanguage: 'english',
    isShowInTimesheet: false,
    colorForDefaultPhoto: `blue`,
    ...doc.data(),
    id: doc.id,
  };

const getUserPath = (id?: string) => `Users${id ? `/${id}` : ``}`;

//#region get collection
const getCollection = () => {
  return db.collection(userCollectionName).withConverter(useUserConverter);
};
//#endregion

//#region user data store
const observingUser$ = defer(() => useAuthService().authorize$).pipe(
  switchMap((auth) => {
    if (!auth) return of(null);

    const { uid } = auth;
    const userRef = db.doc(`${userCollectionName}/${uid}`).withConverter(useUserConverter);
    return fromFirebaseDocRef(userRef).pipe(map((doc) => doc.data()));
  }),
  // this compability with store strategy
  tap((user) => store.commit(`user`, user)),
  shareReplay(1),
);
//#endregion

//#region observing user which react only if user available
const user$: Observable<UserEntry> = observingUser$.pipe(filter<any>((user) => !!user));
//#endregion

//#region get users is worker and available to assigne projects (not be hidden in timesheet)
const filterWorkersCanAssigned = (users: UserEntry[], timesheet: CustomSettingTimeSheetEntry) => {
  return users.filter((user) => !user.isAdmin && !timesheet.hideUsers.includes(user.id));
};
//#endregion

/** user service */
export const useUserService = () => {
  return {
    /** user entry */
    getUserPath,
    getEntry,
    observingUser$,
    user$,
    filterWorkersCanAssigned,
    getCollection,
    userCollectionName,
  };
};
