// collect tool help build up store
import Vue from 'vue';

export const createMutate = (...names: string[]) => {
  const result = {};
  // @ts-ignore
  names.map((name) => (result[`set.${name}`] = (state, value) => Vue.set(state, name, value)));
  return result;
};

// @ts-ignore
export const mapField = (def, cus) => {
  const mappedFields = cus ? cus : def;
  return mappedFields.join(' ');
};
