<template>
  <div>
    <worker-nav-bar />
    <router-view></router-view>
  </div>
</template>

<script>
import WorkerNavBar from "@/components/workers-component/nav-bar-worker";
export default {
  name: 'PageWorkerLayout',
  components: {WorkerNavBar},
  props: {
    ready: { type: Boolean, default: false },
    permkey: { type: String, default: null }
  },
  can: (props) =>{
    if (!props.permkey) return true
    else return acl.hasPerm(props.permkey, 'read')
  }
}
</script>