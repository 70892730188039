import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

var firebaseConfig = {
  apiKey: 'AIzaSyDlbHyf5m6l3yfmZQoSpdaHUygyv22X4n8',
  authDomain: 'control-system-4cef8.firebaseapp.com',
  databaseURL: 'https://control-system-4cef8.firebaseio.com',
  projectId: 'control-system-4cef8',
  storageBucket: 'control-system-4cef8.appspot.com',
  messagingSenderId: '964963968968',
  appId: '1:964963968968:web:642e5b3dc28a2adaff82fd',
  measurementId: 'G-EWFJ4RDWRF',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const funcs = firebase.functions();

db.settings({ ignoreUndefinedProperties: true });
db.enablePersistence({synchronizeTabs:true});
db.waitForPendingWrites();

// if (process.env.NODE_ENV === 'development') {
//   funcs.useEmulator(`localhost`, 5001);
// }

export interface FirebaseEntryBase {
  id: string;
  __path: string;
}

export interface FirebaseEntryEmbededSetting<Values = {}> {
  settings: Values;
}

export type FirebaseConverter<T> = firebase.firestore.FirestoreDataConverter<T>;

/** drop propertyes  */
export const stripFirebaseTempoFields = <T>(obj: T) => {
  type Key = keyof T;
  const keys = Object.keys(obj);
  
  type ExportKey = keyof {[K in Key as K extends `__${string}` ? never : K ]: unknown }
  const exportKey = keys.filter((i:string) => !!i.startsWith('__')) as ExportKey[]

  type StripedObject  = { [K in ExportKey]: T[K] }

  return exportKey.reduce((o, key) => {
    o[key] = (obj)[key]
    return o
  }, {} as any) as StripedObject;
};

export default firebase;
