
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import { useDrawer } from '@/module/drawer/Drawer';

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { open, comp, props, widthSize, setClose, functions } = useDrawer();
    const drawer = ref<HTMLDivElement>();

    // //#region click outside
    // let clickOutside = (e: any) => {
    //   const target = e.target as Element;
    //   if (drawer.value && drawer.value.contains(target)) return;
    //   if (!functions.uncloseIf(target)) setClose();
    // };
    // //#endregion

    // watch(
    //   open,
    //   (isOpen) => {
    //     setTimeout(() => {
    //       if (isOpen) {
    //         window.document.addEventListener('click', clickOutside);
    //       } else {
    //         setClose();
    //         window.document.removeEventListener('click', clickOutside);
    //       }
    //     }, 10);
    //   },
    //   { immediate: true },
    // );

    return { drawer, open, comp, props, setClose, widthSize };
  },
});
