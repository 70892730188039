export default {
  install: Vue => {
    // main dataset table / list
    Vue.component('d-dataset', () => ({
      component: import('./dataset'),
    }))

    // dataset column selectable
    Vue.component('d-dataset-column', () => ({
      component: import('./dataset-column'),
    }))

    // dataset description
    Vue.component('d-dataset-desc', () => ({
      component: import('./dataset-desc'),
    }))

    // dataset footer
    Vue.component('d-dataset-foot', () => ({
      component: import('./dataset-foot'),
    }))
  },
}
