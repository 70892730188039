
import { successToast } from '@/lib/notif';
import { subscribeWithComponent, subscribeWithRef } from '@/lib/UtilObservable';
import { useRouter } from '@/router';
import { useAuthService } from '@/module/auth/AuthService';
import { CompanyEntry, useCompanyService } from '@/module/company/CompanyService';
import { UserEntry, useUserService } from '@/module/user/UserService';
import { defineComponent, Ref, ref } from '@vue/composition-api';

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { user$ } = useUserService();
    const { company$ } = useCompanyService();
    const { logout: authLogout } = useAuthService();
    const router = useRouter();

    const user = <Ref<UserEntry>>ref();
    subscribeWithComponent(subscribeWithRef(user, user$));

    const company = <Ref<CompanyEntry>>ref();
    subscribeWithComponent(subscribeWithRef(company, company$));

    const logout = async () => {
      const { name } = user.value;
      await authLogout();
      successToast(`Good bye ${name}!`);
      router.push({ name: `login` });
    };

    return { user, company, router, logout };
  },
});
