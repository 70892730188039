import beforeEnterLogin from '@/guard/before-enter-login';
import beforeEnterAuth from '@/guard/before-enter-auth';
import loading from '@/guard/preload.vue';

const makeComponent = (get: any) => () => ({
  component: get(),
  loading,
});

const meta = (obj: any) => Object.assign({ layout: 'main' }, obj);

export const LoginRoutes = [
  {
    name: 'login',
    path: '/sign-in',
    component: makeComponent(() => import(/* webpackChunkName: 'login' */ './index.vue')),
    meta: meta({ title: 'Sign In', layout: 'custom' }),
    beforeEnter: beforeEnterLogin,
    redirect: { name: 'choose.method' },
    children: [
      {
        name: 'choose.method',
        path: '/',
        component: makeComponent(() => import(/* webpackChunkName: 'choose.method' */ './pages/choose_method.vue')),
        meta: meta({ title: 'Sign In', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'login.email',
        path: '/sign-in-with-email',
        component: makeComponent(() => import(/* webpackChunkName: 'login.email' */ './pages/login_email.vue')),
        meta: meta({ title: 'Sign In', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'login.phone',
        path: '/sign-in-with-phone',
        component: makeComponent(() => import(/* webpackChunkName: 'login.phone' */ './pages/login_phone.vue')),
        meta: meta({ title: 'Sign In', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'login.confirm.phone',
        path: '/sign-in-confirm-phone',
        component: makeComponent(() => import(/* webpackChunkName: 'confirm.phone' */ './pages/confirm_phone.vue')),
        meta: meta({ title: 'Confirm a phone number', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'login.finish.register',
        path: '/sign-in-create-account',
        component: makeComponent(() => import(/* webpackChunkName: 'login.account' */ './pages/finish_register.vue')),
        meta: meta({ title: 'Create Account', layout: 'custom' }),
        beforeEnter: beforeEnterAuth,
      },
    ],
  },
];
