
import Sidebar from '@/sidebar/Sidebar.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { Sidebar },
  props: {},
  setup(props) {
    return {};
  },
});
