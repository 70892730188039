import beforeEnterLogin from '@/guard/before-enter-login';
import loading from '@/guard/preload.vue';

const makeComponent = (get: any) => () => ({
  component: get(),
  loading,
});

const meta = (obj: any) => Object.assign({ layout: 'main' }, obj);

export const RegisterRoutes = [
  {
    name: 'register',
    path: '/register',
    component: makeComponent(() => import(/* webpackChunkName: 'register' */ './index.vue')),
    meta: meta({ title: 'Create Account', layout: 'custom' }),
    beforeEnter: beforeEnterLogin,
    redirect: { name: 'create.account' },
    children: [
      {
        name: 'create.account',
        path: '/',
        component: makeComponent(() => import(/* webpackChunkName: 'create.account' */ './pages/create_user.vue')),
        meta: meta({ title: 'Create Account', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'set.password',
        path: '/set-password',
        component: makeComponent(() => import(/* webpackChunkName: 'set.password' */ './pages/set_password.vue')),
        meta: meta({ title: 'Set a password', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
      {
        name: 'confirm.phone',
        path: '/confirm-phone-number',
        component: makeComponent(() => import(/* webpackChunkName: 'confirm.phone' */ './pages/confirm_phone.vue')),
        meta: meta({ title: 'Confirm a phone number', layout: 'custom' }),
        beforeEnter: beforeEnterLogin,
      },
    ],
  },
];
