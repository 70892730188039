//

import { defer, fromEvent, of } from 'rxjs';
import { combineLatestWith, concatWith, debounceTime, map, shareReplay } from 'rxjs/operators';

export enum ThemeBreakpoint {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum ThemeWidthRange {
  MOBILE,
  TABLET,
  DESKTOP,
  WIDE,
  LARGE_WIDE,
  MAX_WIDE,
}

// keep observing window width after get one time current window width.
const windowSize$ = defer(() => of(window.innerWidth)).pipe(
  concatWith(fromEvent(window, 'resize')),
  debounceTime(100),
  map(() => window.innerWidth),
  shareReplay(1),
);

//#region detect current window width drop in one of theme break point

const widthRange$ = windowSize$.pipe(
  map((width) => {
    const { SM, XXL, LG, MD, XL } = ThemeBreakpoint;
    const { DESKTOP, MAX_WIDE, LARGE_WIDE, MOBILE, TABLET, WIDE } = ThemeWidthRange;
    if (width < SM) return MOBILE;
    if (width < MD) return TABLET;
    if (width < LG) return DESKTOP;
    if (width < XL) return WIDE;
    if (width < XXL) return LARGE_WIDE;
    return MAX_WIDE;
  }),
  shareReplay(1),
);
//#endregion

/** manager global values regard to theme */
export const useThemeService = () => {
  return { windowSize$, widthRange$ };
};
