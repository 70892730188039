import { createMutate } from '@/lib/store-helper'

export default {
    namespaced: true,
    state: {
        isEmailSignUp: true,
        input: null,
        recaptchaVerifier: null,
        confirmationResult: null
    },
    mutations: {
        ...createMutate('isEmailSignUp', 'input', 'recaptchaVerifier', 'confirmationResult')
    },
    actions: {
        
    }
}