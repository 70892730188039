/*
GUARD ROUTE
guard before login page and register page
make sure not login before
redirect to prevvious page if flag working is yes
or go to dashboard
*/

import metaUpdate from './meta-update';
import { NavigationGuard } from 'vue-router';
import { firstValueFrom } from 'rxjs';
import { useAuthService } from '@/module/auth/AuthService';

const main: NavigationGuard = async (to, from, next) => {
  const { authorize$ } = useAuthService();

  /* update meta tags */
  metaUpdate(to.meta);

  const auth = await firstValueFrom(authorize$);
  if (auth) next('/');
  else next();
};

export default main;
