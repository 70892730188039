import firebase, { auth } from '@/module/firebase';
import store from '@/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

//#region observing authorize thought firebase lib
const onAccountChanged$ = new Observable<null | firebase.User>((out) => {
  const unsub = auth.onAuthStateChanged((i) => out.next(i));
  return () => unsub();
}).pipe();
//#endregion

//#region observing last result from firebase auth lib
const authorize$ = onAccountChanged$.pipe(shareReplay(1));
//#endregion

//#region logout thought firebase auth lib
const logout = async () => {
  await auth.signOut();
  // compability with store strategy
  store.commit('user', null);
};
//#endregion

export const useAuthService = () => {
  return {
    onAccountChanged$,
    authorize$,
    logout,
  };
};
