export const decor = {
  field: () => import(/* webpackChunkName: 'control' */ './field'),
  button: () => import(/* webpackChunkName: 'control' */ './button'),
  email: () => import(/* webpackChunkName: 'control' */ './email'),
  password: () => import(/* webpackChunkName: 'control' */ './password'),
  number: () => import(/* webpackChunkName: 'control' */ './number'),
  text: () => import(/* webpackChunkName: 'control' */ './text'),
  form: () => import(/* webpackChunkName: 'control' */ './form'),
  radio: () => import(/* webpackChunkName: 'control' */ './radio'),
  usd: () => import(/* webpackChunkName: 'control' */ './usd'),
  tabToggle: () => import(/* webpackChunkName: 'control' */ './tab-toggle'),
  confirm: () => import(/* webpackChunkName: 'control' */ './confirm'),
  autocomplete: () => import(/* webpackChunkName: 'control' */ './autocomplete'),
}

export const install = {
  install: Vue => {
    Vue.component('control-field', decor.field)
    Vue.component('control-button', decor.button)
    Vue.component('control-email', decor.email)
    Vue.component('control-password', decor.password)
    Vue.component('control-number', decor.number)
    Vue.component('control-form', decor.form)
    Vue.component('control-text', decor.text)
    Vue.component('control-radio', decor.radio)
    Vue.component('control-usd', decor.usd)
    Vue.component('control-tab-toggle', decor.tabToggle)
    Vue.component('control-confirm', decor.confirm)
    Vue.component('control-autocomplete', decor.autocomplete)
  },
}
