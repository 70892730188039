import beforeEnterAuth from "@/guard/before-enter-auth";
import loading from "@/guard/preload";

const makeComponent = get => () => ({
  component: get(),
  loading
});

const meta = obj => Object.assign({ layout: "worker" }, obj);

export const WorkerRoutes = [
  {
    name: 'worker-app',
    path: '/app',
    component: makeComponent(() =>
      import(/* webpackChunkName: 'worker-timesheet' */ "../viewsWorker/timesheet/timesheet")
    ),
    meta: meta({ title: "Worker" }),
    beforeEnter: beforeEnterAuth,
    children: [
      {
        name: 'worker-app-overview',
        path: '/app',
        component: makeComponent(() =>
          import(/* webpackChunkName: 'worker-timesheet-overview' */ "../viewsWorker/timesheet/components/view-detail/view")
        ),
        meta: meta({ title: "Worker" }),
      },
      {
        name: 'worker-app-add',
        path: '/app/work-log',
        component: makeComponent(() =>
          import(/* webpackChunkName: 'worker-timesheet-action' */ "../viewsWorker/timesheet/components/add-work-log/open-work-component")
        ),
        meta: meta({ title: "Worker" }),
      },
      {
        name: 'worker-app-update',
        path: '/app/work-log-update/:workLogId',
        component: makeComponent(() =>
          import(/* webpackChunkName: 'worker-timesheet-action-update' */ "../viewsWorker/timesheet/components/add-work-log/update-work-component")
        ),
        meta: meta({ title: "Worker" }),
      },
    ]
  }
]