
import { defineComponent } from '@vue/composition-api';
import SidebarMenuLink from './SidebarMenuLink.vue';

export default defineComponent({
  components: { SidebarMenuLink },
  props: {},
  setup() {
    return {};
  },
});
