import { fromFirebaseDocRef } from '@/lib/UtilObservable';
import firebase, { db, FirebaseConverter, FirebaseEntryBase } from '@/module/firebase';
import store from '@/store';
import { defer, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { useUserService } from '@/module/user/UserService';

export interface CompanyEntry extends FirebaseEntryBase {
  managerNumber: string;
  name: string;
  admins: string[];
  drivers: unknown[];
}

//#region converter
export const useCompanyConverter: FirebaseConverter<CompanyEntry> = {
  fromFirestore: (entry) => {
    const item = <CompanyEntry>{
      admins: <string[]>[],
      drivers: <unknown>[],
      ...entry.data(),
      id: entry.id,
      __path: entry.ref.path,
    };
    return item;
  },
  toFirestore: ({ id, __path, admins = [], drivers = [], ...others }) => {
    return { admins, drivers, ...others };
  },
};
//#endregion

export const useCompanyService = () => {
  return {
    collectionName,
    getCollection,
    getCompanyPath,
    getEntry,
    company$,
    companyId$,
    observingCompany$,
  };
};

const collectionName = 'Companies';

const getCollection = () => {
  return db.collection(collectionName).withConverter(useCompanyConverter);
};

export const fetchCompanyInfo = async () => {
  const { companyId, id } = <any>store.state.user;
  return getCollection()
    .doc(companyId)
    .get()
    .then(getEntry);
};

const getCompanyPath = (id?: string) => `Companies${id ? `/${id}` : ``}`;

const getEntry = (snap: firebase.firestore.DocumentSnapshot<CompanyEntry>) => snap.data();

//#region observing company
const observingCompany$ = defer(() => useUserService().user$).pipe(
  switchMap((user) => {
    if (!user) return of(null);
    const { companyId } = user;
    return fromFirebaseDocRef(getCollection().doc(companyId)).pipe(map(getEntry));
  }),
  // this compability with store strategy
  tap((company) => store.commit(`companyInfoStore/companyInfo`, company)),
  shareReplay(1),
);
//#endregion

//#region observing company, react when company available
const company$: Observable<CompanyEntry> = observingCompany$.pipe(filter<any>((company) => !!company));

const companyId$ = company$.pipe(
  map((i) => i.id),
  shareReplay(1),
);
//#endregion
