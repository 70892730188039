
import { useRouter } from '@/router';
import { computed, defineComponent } from '@vue/composition-api';
import SidebarMenuLink from './SidebarMenuLink.vue';

export default defineComponent({
  components: { SidebarMenuLink },
  props: {},
  setup() {
    const router = useRouter();
    const isActivePlaceDetail = computed(() => router.currentRoute.name === 'project_detail');

    return {
      isActivePlaceDetail,
    };
  },
});
